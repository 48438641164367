<template>
  <ModalAlert
    :show="props.show"
    title="アイテム購入確認"
    size="xl"
    :is-enable-ok="false"
    :is-enable-cancel="false"
    @click-ok="onBuyProcess"
    @click-cancel="onCancel"
  >
    <div class="-my-5 pt-5 px-3 text-center flex flex-col w-full h-full">
      <div class="flex flex-col grow w-full h-full">
        <CommonUserGem :diamond-shop="true" class="pb-4" @on-add-point="onCancel" />
        <div class="grow overflow-y-auto -mx-3 px-3">
          <div v-if="props.message" class="mb-3">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="props.message" />
          </div>

          <ModalInnerInfo class="mb-5" align="left">
            <div class="item-title text-lg mb-2">
              {{ props.shopItem.shopItemName }}
            </div>
            <div class="flex flex-row justify-between">
              <div class="grow-0">
                <ResourceImg
                  v-if="props.shopItem"
                  asset="item"
                  :partition="props.shopItem.resourcePartition ?? 0"
                  :cd="props.shopItem.resourceItemCd ?? ''"
                  class="item-image"
                />
              </div>
              <div class="grow px-4 text-left">
                <CommonText :text="props.shopItem.shopItemDescription" />
              </div>
            </div>
          </ModalInnerInfo>
          <CommonButton class="w-full mb-4" type="info" :disabled="props.buyMaxCount <= 1" @click="onBuyCountEdit">
            ▼購入数: {{ buyCount }}
          </CommonButton>
          <div class="w-full">
            <div v-for="(it, k) of shopChangeDataList" :key="k" class="mb-3">
              <CommonChangeValue :title="it.name" :before="it.before" :after="it.before + it.value" />
            </div>
          </div>
          <ModalShopCostInfo :cost="props.cost" class="w-full" />
        </div>
        <div class="flex flex-col justify-center modal-buttons mt-2">
          <div class="text-center">
            <SpecifiedCommercialButton />
          </div>
          <div class="flex justify-center">
            <CommonButton type="secondary" class="modal-btn flex flex-col items-center justify-center" @click="onCancel">
              <div>
                キャンセル
              </div>
            </CommonButton>
            <ModalShopBuyButton @buy="onBuyProcess" @cancel="onCancel" />
          </div>
        </div>
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import {
  ShopItem,
  UserItem,
  ShopItemBuyType, UserShopLimitItem,
} from 'api';
import { getShopChangeData, isCanBuyCost, isCanBuyShopItem, ShopChangeData } from '~/libs/page/shop/type';
import { ShopBuyCost, useWallet } from '~/composables/useWallet';

interface Props {
  show: boolean,
  shopItem: ShopItem,
  userItem?: UserItem,
  userShopLimitItem?: UserShopLimitItem,
  buyCount: number,
  buyMaxCount: number,
  buyType: ShopItemBuyType,
  cost: ShopBuyCost,
  message?: string,
}
const props = withDefaults(defineProps<Props>(), {
  userItem: undefined,
  userShopLimitItem: undefined,
  buyCount: 1,
  buyMaxCount: 1,
  message: undefined,
});
interface Emits {
  (e: 'onBuy', shopItem: ShopItem): void;
  (e: 'onChangeSelectCount', shopItem: ShopItem): void;
  (e: 'onCancel', shopItem: ShopItem): void;
}
const emit = defineEmits<Emits>();

const shopChangeDataList = ref<ShopChangeData[]>([]);
const isCanBuy = ref<boolean>(false);
const canBuyCostEnable = ref<boolean>(false);
/**
 * 初期化処理
 */
const init = (data: Props) => {
  calcChangeParams(data, data.buyCount);
  canBuyCostEnable.value = isCanBuyCost({
    cost: data.cost,
    wallet: useWallet().wallet.value,
    animateWallet: useAnimateWallet().wallet.value,
  });
  isCanBuy.value = canBuyCostEnable.value && isCanBuyShopItem(data.shopItem, useLabotan().date, data.userShopLimitItem);
};
onMounted(() => {
  init(props);
});
watch(props, data => init(data));

/**
 * データの変化量を計算
 */
const calcChangeParams = (data: Props, count: number) => {
  // 変化量を計算
  shopChangeDataList.value = getShopChangeData({
    shopItem: data.shopItem,
    userItem: data.userItem,
    buyCount: count,
    userStatus: useUser().userStatus.value,
  });
};
/**
 * 購入数変更
 */
const onBuyCountEdit = () => {
  emit('onChangeSelectCount', props.shopItem);
};
/**
 * 商品購入処理実行
 */
const onBuyProcess = () => {
  emit('onBuy', props.shopItem);
};
/**
 * 商品購入キャンセル
 */
const onCancel = () => {
  emit('onCancel', props.shopItem);
};
</script>

<style lang="scss" scoped>
.item-title {
  color: #947f2f;
}

.item-image {
  width: 68px;
  height: 68px;
}

.modal-btn {
  min-width: 124px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.buy-btn {
  font-size: 0.8rem;
}
</style>
